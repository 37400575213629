import { useEffect, useState } from "react";
import styled from "styled-components";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import '@splidejs/splide/dist/css/splide.min.css';
import { Link } from "react-router-dom";



function Popular() {
  const [popular, setPopular] = useState([]);

  useEffect(() => {
    getPopular();
  }, []);

  const getPopular = async () => {

    const check = localStorage.getItem('popular')

    if(check){
        setPopular(JSON.parse(check))
    } else {
        const api = await fetch(`https://api.spoonacular.com/recipes/random?apiKey=${process.env.REACT_APP_API_KEY}&number=10`);
        const data = await api.json();


        localStorage.setItem('popular', JSON.stringify(data.recipes))
        console.log(data.recipes);
        setPopular(data.recipes);

    }



    
  };

  return (
    <div>
      <Wrapper>
        <h3> Popular Picks </h3>
        <Splide
          options={{
            perPage: 4,
            arrows: true,
            pagination: true,
            drag: "free",
            gap: '5rem'
          }}
        >
          {popular.map((recipe) => {
            return (
              <SplideSlide key={recipe.id}>
                <Card>
                    <Link to= {"/recipe/" + recipe.id}> 
                    <p><span>{recipe.title}</span></p>
                    <img src={recipe.image} alt="recipe img" />
                    <Gradient />
                  </Link>
                </Card>
              </SplideSlide>
            );
          })}
        </Splide>
      </Wrapper>
    </div>
  );
}



const Wrapper = styled.div`
@media screen and (max-width: 450px) {
  justify-content: center;
  align-items: center;
  margin: 0px;
  margin-top: 20px;
  
  width: 60rem; 
  height: 15rem; 
  p span {
   color: black; }
   p{
    background: white;
  }
   
  }
 

margin: 5rem 10rem;
`;

const Card = styled.div`
  min-height: 15rem;
  border-radius:4rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 10px 5px #FFD68A;



  
  

  p {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, 0%);
    color: white;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    height: 40%;
    display: flex;
    justify-content: center;
    
    
  }


  img {
    
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  &:hover {
    box-shadow: 0px 0px 15px 5px #482908; /* Adjusted box shadow on hover */
    transform: scale(1.05); /* Added scale transformation on hover */
  }

  &:hover p {
    color: #482908; /* Change text color on hover */
  }

  &:hover p span {
    background-color: white; /* Change text color on hover */
    width: 100%
  }
`;



const Gradient = styled.div`
z-index: 3; 
position: absolute;
width: 100%;
height: 100%;
background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5))
`

export default Popular;
